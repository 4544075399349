import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitMenuClick(_ref) {
  var name = _ref.name,
      url = _ref.url,
      _ref$item_path = _ref.item_path,
      item_path = _ref$item_path === void 0 ? [] : _ref$item_path,
      _ref$source_type = _ref.source_type,
      source_type = _ref$source_type === void 0 ? null : _ref$source_type;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/menu_click/jsonschema/1-0-1",
      data: {
        name: name,
        url: url,
        item_path: item_path,
        source_type: source_type
      }
    }
  });
}